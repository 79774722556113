@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';


.appSectionContainer {
  background-color: var(--color-semantic-surface-spotlight-accent);
  color: var(--color-semantic-text-on-button-critical);;
}

.cardHeight {
  height: 200px;
}

.QrCodeContainer {
  display: none;
  
  @include media-query.breakpoint(md) {
    display: block;
  }
}

.goToAppCTA {
  display: block;
  
  @include media-query.breakpoint(md) {
    display: none;
  }
}

.qrCodeCard {
  background-color: var(--color-semantic-surface-spotlight-accent);
}

.qrCodeCardWrapper {
  max-height: 126px;
  width: 126px;
  height: 100%;
}

.qrCodeSvg {
  width: 100%;
  height: 100%;
}

.images {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

