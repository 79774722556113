@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

$sticky-header-z-index: 2;

.Sticky {
  position: sticky;
  top: 0;
  width: 100%;

  z-index: $sticky-header-z-index;
  background: var(--color-semantic-background-subdued);
}

.LgSticky {
  @include media-query.breakpoint(lg) {
    position: sticky;
    top: 0;
    z-index: $sticky-header-z-index;
    isolation: isolate;
  }
}

.NavbarMegaMenu {
  position: relative;
  grid-area: megaMenu;
  background-color: var(--color-semantic-background-subdued);
  border-bottom: 1px solid var(--color-semantic-border-default);
}