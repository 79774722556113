@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.ColumnRight {
  display: flex;

  img {
    clip-path: url(#mask);
  }

  @include media-query.breakpoint(null, md) {
    display: none;
  }
}

.imageContainer {
  height: auto;

  @include media-query.breakpoint(xl) {
    border-radius: 20px;
    overflow: hidden;
  }
}

.imageContainer > picture > img {
  height: auto;
}
