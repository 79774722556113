@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.mainContainer {
  width: 100%;
  position: relative;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  display: none;

  @include media-query.breakpoint(md) {
    display: block;
  }
}

.card {
  border-radius: 16px;
  width: 448px;

  @include media-query.breakpoint(null, md) {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
}
