@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.fillBrandRed {
  fill: var(--color-semantic-illustration-default);
}

.sizeMd {
  max-width: 330px;

  @include media-query.breakpoint(null, sm) {
    max-width: 225px;
  }
}

.fullWidthAndHeight {
  width: 100%;
  height: 100%;
}
