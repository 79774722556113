@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.ColumnLeft,
.sectionRow > div,
.InnerBox {
  height: 100%;
}

.InnerBox {
  z-index: 10;
}

.sectionContainer {
  @include media-query.breakpoint(null, md) {
    position: relative;
    height: 560px;

    .heading {
      color: var(--color-semantic-text-on-button-critical);
      text-align: center;
      // Shadow to increase contrast when text is dispalyed on top of an image
      text-shadow: 0px 4px 20px #2e1708;
    }

    .callToAction {
      margin-top: auto;
      align-self: end;
      width: 100%;
    }
  }

  .mobileImageContainer {
    height: 560px;
    width: 100%;
    z-index: 1;

    @include media-query.breakpoint(md) {
      display: none;
    }
  }

  .mobileImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .sectionRow {
    top: 0;
    left: 0;
    width: 100%;

    @include media-query.breakpoint(null, md) {
      position: absolute;
      height: 100%;
    }
  }
}
