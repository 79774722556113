.container {
  max-height: 200px;
  width: 100%;
  height: 200px;
  margin-top: 48px;
  position: relative;
}

.largeContainer {
  max-height: 556px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
