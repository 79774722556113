@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.masonryParent {
  display: flex;
  width: 100%;
  justify-content: center;
}

.masonryContainer {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  grid-template-rows: repeat(13, 50px);
  grid-gap: 16px;
  max-width: 100vw;
  overscroll-behavior-x: none;

  @include media-query.breakpoint(null, lg) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-gap: 8px;
  }
}

.innerImage {
  height: 100%;
  width: 100%;

  transition: transform 0.2s ease-in-out;
}
.image {
  border-radius: var(--border-radius-medium);
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: relative;

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translate(0px, -4px);

    .innerImage {
      transform: scale(1.05);
    }
  }
}
@include media-query.breakpoint(lg) {
  .smallScreenHeader {
    display: none;
  }
  .headerContainer {
    grid-area: 1 / 1 / 5 / 11;

    .header {
      margin-left: auto;
      max-width: 524px;
      text-align: end;
    }
  }
  .image-0 {
    grid-area: 1 / 11 / 9 / 22;
  }
  .image-1 {
    grid-area: 5 / 1 / 11 / 11;
  }
  .image-2 {
    grid-area: 9 / 11 / 13 / 18;
  }
  .image-3 {
    grid-area: 11 / 6 / 14 / 11;
  }
}
@include media-query.breakpoint(null, lg) {
  .masonryContainer {
    grid-template-rows: repeat(11, 39px);
  }
  .masonryParent {
    flex-direction: column;
  }
  .smallScreenHeader {
    justify-content: center;
    justify-self: center;
  }
  .headerContainer {
    display: none;
  }
  .header {
    text-align: center;
  }
  .image-0 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 7;
    grid-column-end: span 6;
  }
  .image-1 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-row-end: span 5;
    grid-column-end: span 6;
  }
  .image-2 {
    grid-row-start: 7;
    grid-column-start: 7;
    grid-row-end: span 5;
    grid-column-end: span 6;
  }
  .image-3 {
    grid-row-start: 8;
    grid-column-start: 3;
    grid-row-end: span 3;
    grid-column-end: span 4;
  }
}

.recipeCTAButton {
  min-width: 308px;
}
