@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.noUnderline,
.noUnderline:hover {
  text-decoration: none !important;
}

.boxShadowNone {
  box-shadow: none;
}

.hugeCard {
  width: 202px;
  min-height: 300px;

  @include media-query.breakpoint(md) {
    width: 260px;
    min-height: 360px;
  }
}

.description {
  text-overflow: ellipsis;
  overflow: hidden;
}

.image {
  border-top-left-radius: var(--spacing-4);
  border-top-right-radius: var(--spacing-4);
}
