@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.scrollingWrapperFlexbox {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    gap: 16px;
  }
  > div > a:last-of-type {
    padding-right: var(--spacing-5);
  }
}

.fadeBoxContainer {
  position: relative;
  isolation: isolate;
}
.fadeBoxLeft {
  position: absolute;
  z-index: 100;
  background: linear-gradient(
    90deg,
    var(--color-semantic-background-default) 0%,
    transparent 100%
  );
  height: 310px;
  width: 20px;
  top: 0;
  left: 0;
}
.fadeBoxRight {
  position: absolute;
  z-index: 100;
  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--color-semantic-background-default) 100%
  );
  height: 310px;
  width: 20px;
  top: 0;
  right: 0;
}

@include media-query.breakpoint(md) {
  $n: 4;
  $animationLength: 500ms;

  @keyframes fade-in {
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .listItemDefault {
    opacity: 0;
  }

  .listItem {
    opacity: 0;
    transform: translateY(50px);
    animation: fade-in $animationLength ease forwards;

    @for $x from 0 through $n {
      &:nth-child(#{$x}) {
        animation-delay: $x * calc($animationLength / 4);
      }
    }
  }
}

@media (prefers-reduced-motion) {
  .listItem {
    opacity: 1;
    transform: translateY(0px);
    animation: none;
  }
}
